// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type {
  ExamTranscript,
  ExamTranscriptSummary,
  OrbitApiMeta
} from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

// utilities
import { deepClone } from '@revolutionprep/utils'
import { compareDesc } from 'date-fns'

export const useExamTranscriptSummaryStore =
  defineStore('exam-transcript-summary', () => {
    // fetch repositories
    const { $transcriptsSummary } = useNuxtApp()

    // state
    const summary = ref<ExamTranscriptSummary[]>([])

    // actions
    async function index (config?: NitroFetchOptions<string>) {
      const { summary: _examTranscriptSummary } =
        await $transcriptsSummary
          .index<{ summary: ExamTranscriptSummary[], meta: OrbitApiMeta }>(
            config
          )
      summary.value = _examTranscriptSummary as ExamTranscriptSummary[] || []
      return summary
    }

    // getters
    function getSortedTranscriptSummary () {
      const transcriptsSummary = deepClone(summary.value)
      // sort transcript arrays in reverse chronological order
      const sortTranscripts = (transcripts: ExamTranscript[]) => {
        return deepClone(transcripts).sort((transcriptA, transcriptB) => {
          return compareDesc(
            new Date(transcriptA.exam.scored_on),
            new Date(transcriptB.exam.scored_on)
          )
        })
      }
      const sortedTranscripts = transcriptsSummary.map((summary) => {
        return {
          ...summary,
          transcripts: sortTranscripts(summary.transcripts)
        }
      })
      // sort summary arrays in reverse chronological order
      const sortSummaries = (summary: ExamTranscriptSummary[]) => {
        return deepClone(summary).sort((summaryA, summaryB) => {
          return compareDesc(
            new Date(summaryA.transcripts[0].exam.scored_on),
            new Date(summaryB.transcripts[0].exam.scored_on)
          )
        })
      }

      return sortSummaries(sortedTranscripts)
    }

    return {
      summary,
      getSortedTranscriptSummary,
      index
    }
  })
